/* CookieConsent.css */
.cookie-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 20px;
    text-align: center;
    z-index: 1000; /* Asegura que el mensaje esté por encima de otros elementos */
  }
  
  .cookie-consent p {
    margin: 0 0 10px 0;
  }
  
  .cookie-consent button {
    background-color: #f0f0f0;
    color: #333;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  